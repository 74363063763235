export function showCategoryLabel(product) {
  return (
    (product.bottles_left > 0 && product.bottles_left < 25) ||
    product.is_customer_favorite ||
    product.is_wine_team_favorite ||
    product.is_last_chance ||
    product.is_organic ||
    product.is_offer
  )
}

export function categoryLabelText(product, hideOffers = false) {
  return product.bottles_left > 0 && product.bottles_left < 25
    ? bottlesLeftText(product)
    : product.is_offer && !hideOffers
    ? 'Limited Time Offer'
    : product.is_organic
    ? 'Organic'
    : product.is_last_chance
    ? 'Last Chance'
    : product.is_wine_team_favorite
    ? 'Wine Team Favorite'
    : product.is_customer_favorite
    ? 'Member Favorite'
    : ''
}

export function calculateDisplayPrice(product, qty) {
  // Total of 3 Tiers and 2 Thresholds
  // No Tiers
  if (!product.discount_threshold) {
    return product.price_excl_tax
  }
  // One or Two or Three Tiers and qty below threshold 1
  else if (product.discount_threshold && qty < product.discount_threshold) {
    return product.price_excl_tax
  }
  // Two Tiers and qty above threshold 1
  else if (
    product.discount_threshold &&
    qty >= product.discount_threshold &&
    !product.deeper_discount_threshold
  ) {
    return product.discount_price_excl_tax
  }
  // Three Tiers and qty above threshold 1 but below threshold 2
  else if (
    product.deeper_discount_threshold &&
    qty >= product.discount_threshold &&
    qty < product.deeper_discount_threshold
  ) {
    return product.discount_price_excl_tax
  }
  // Three Tiers and qty above threshold 2
  else if (
    product.deeper_discount_threshold &&
    qty >= product.deeper_discount_threshold
  ) {
    return product.deeper_discount_price_excl_tax
  }
}

export function itemSizeStr(product) {
  if (!product.size_str || product.size_str === '750ml') {
    return ''
  } else {
    return `(${product.size_str})`
  }
}

export function calculateDisplayBottles(product, qty) {
  // Total of 3 Tiers and 2 Thresholds
  // No Tiers
  if (!product.discount_threshold) {
    return ''
  }
  // Two or Three Tiers and qty below threshold 1
  else if (product.discount_threshold && qty < product.discount_threshold) {
    return (
      '1-' +
      (product.discount_threshold - 1).toString() +
      ' bottles ' +
      itemSizeStr(product)
    )
  }
  // Two Tiers and qty above threshold 1
  else if (
    product.discount_threshold &&
    qty >= product.discount_threshold &&
    !product.deeper_discount_threshold
  ) {
    return (
      product.discount_threshold.toString() +
      '+ bottles ' +
      itemSizeStr(product)
    )
  }
  // Three Tiers and qty above threshold 1 but below threshold 2
  else if (
    product.deeper_discount_threshold &&
    qty >= product.discount_threshold &&
    qty < product.deeper_discount_threshold
  ) {
    return (
      product.discount_threshold.toString() +
      '-' +
      (product.deeper_discount_threshold - 1).toString() +
      ' bottles ' +
      itemSizeStr(product)
    )
  }
  // Three Tiers and qty above threshold 2
  else if (
    product.deeper_discount_threshold &&
    qty >= product.deeper_discount_threshold
  ) {
    return (
      product.deeper_discount_threshold.toString() +
      '+ bottles ' +
      itemSizeStr(product)
    )
  }
}

export function bottlesLeftText(product) {
  const now = new Date().getTime()
  const endTime = new Date(product.end_datetime).getTime()
  const leftTime = endTime - now
  if (endTime > 0 && leftTime <= 0 && product.is_offer) {
    return 'Out of Stock'
  } else if (product.bottles_left > 99) {
    return '99+ left'
  } else {
    return `${product.bottles_left} left`
  }
}

export function scrollToProductInCarousel($store, $nextTick) {
  const productID = $store.getters.productIDInView
  const carouselID = $store.getters.carouselInView
  if (productID !== undefined && carouselID !== undefined) {
    const query = `div[data-carousel-id="${carouselID}"] ul[class="product-grid"] > li[data-product-id="${productID}"]`
    $nextTick(() => {
      const element = document.querySelector(query)
      if (element) {
        element.scrollIntoView({
          behavior: 'smooth',
          block: 'end',
          inline: 'nearest',
        })
      }
      $store.dispatch('setProductIDInView', undefined)
      $store.dispatch('setCarouselInView', undefined)
    })
  }
}
