import { setUser } from '~/../common/utils/user'

export const getCustomerCredits = async (axios) =>
  (
    await axios.get('/api/customer/credits/').catch((e) => {
      window.location = '/accounts/login/?next=/accounts/credits/'
    })
  ).data

export const submitProfile = async ($axios, $auth, data) => {
  try {
    await $axios.post('/api/customer/profile/', data)
    const user = $auth.user
    user.email = data.email
    user.date_of_birth = data.date_of_birth
    user.first_name = data.first_name
    user.last_name = data.last_name
    setUser($auth, user)
  } catch (e) {
    console.error(e)
    throw e
  }
}

export const patchProfile = async ($axios, $auth, data) => {
  try {
    await $axios.patch('/api/customer/profile/', data)
    const user = $auth.user
    user.email = data.email
    user.date_of_birth = data.date_of_birth
    user.first_name = data.first_name
    user.last_name = data.last_name
    setUser($auth, user)
  } catch (e) {
    console.error(e)
    throw e
  }
}

export const checkoutRegister = async ($axios, $auth, data) => {
  try {
    const resp = await $axios.post('/api/customer/register/', data, {
      params: {
        checkout: 1,
      },
    })
    setUser($auth, resp.data.user, resp.data.user.token)
  } catch (e) {
    console.error(e)
    throw e
  }
}

export const checkoutUpdateUser = async ($axios, $auth, data) => {
  try {
    await $axios.post('/api/customer/profile/', data, {
      params: {
        checkout: 1,
      },
    })
    const user = $auth.user
    user.email = data.email
    user.date_of_birth = data.date_of_birth
    user.first_name = data.first_name
    user.last_name = data.last_name
    setUser($auth, user)
  } catch (e) {
    console.error(e)
    throw e
  }
}

export function showPersonalized($auth) {
  return $auth.loggedIn && $auth.user.status === 'existing'
}
