<template>
  <div class="homepage">
    <div class="b24">
      <div v-if="homepageHeroWidgetData" class="hero hero--seasonal">
        <div class="hero__main">
          <div class="hero__copy">
            <div v-if="$auth.loggedIn" class="hero__welcome">
              Welcome back, {{ $auth.user.first_name }}
            </div>
            <h1>
              <template v-if="homepageHeroWidgetData.headline_link">
                <a :href="homepageHeroWidgetData.cta_link">{{
                  homepageHeroWidgetData.headline
                }}</a>
              </template>
              <template v-else>
                <strong>{{ homepageHeroWidgetData.headline }}</strong>
              </template>
            </h1>
            <div v-html="homepageHeroWidgetData.copy" />
          </div>
          <div class="hero__cta">
            <a
              :href="homepageHeroWidgetData.cta_link"
              class="longarrow-black"
              >{{ homepageHeroWidgetData.cta_text }}</a
            >
          </div>
        </div>
        <div class="hero__img">
          <img
            v-lazy-load :data-src="homepageHeroWidgetData.image_url"
            class="hero__img--desktop"
            alt=""
          />
          <img
            v-lazy-load :data-src="homepageHeroWidgetData.image_url"
            class="hero__img--mobile"
            alt=""
          />
        </div>
      </div>
      <div v-if="$auth.loggedIn" class="pill-scroller">
        <div class="pill-scroller__content">
          <div
            class="pill-scroller__label"
            :class="{ has_saved_searches: savedSearchList.length > 0 }"
          >
            {{ savedSearchList.length > 0 ? 'Saved' : 'Recently Shopped' }}
          </div>
          <ul>
            <li
              v-for="search in savedSearchList.length
                ? savedSearchList
                : personalizedSearches"
              :key="search.id || search.slug"
            >
              <nuxt-link
                :to="
                  savedSearchList.length
                    ? `/store/search/?${decodeURIComponent(
                        search.original_query_string
                      )}`
                    : `/store/search/?category=${search.slug}`
                "
              >
                {{ search.display_name || search.name }}
              </nuxt-link>
            </li>
          </ul>
          <div v-if="savedSearchList.length > 0" class="pill-scroller__more">
            <nuxt-link to="/cellar/searches/">Add More</nuxt-link>
          </div>
          <div v-else class="pill-scroller__more">
            <nuxt-link to="/store/">Shop all</nuxt-link>
          </div>
        </div>
      </div>
      <div v-if="isPersonalized && userHasOrders" class="site-width">
        <div class="row feature-blocks p-0 mt-0">
          <div
            class="columns feature-block feature-block--gray f22-header px-md-0 bg-mycellar text-left w-full"
          >
            <div class="columns-12-10-8-6 bg-mycellar__placement">
              <h2>Shop Your Past Purchases</h2>
              <nuxt-link to="/cellar/wines/" class="link-overlay">
                <span class="sr-only">Go to My Cellar</span>
              </nuxt-link>
              <p class="large">
                Plus, explore the wines most similar to those bottles.
              </p>
              <p class="feature-block__cta">
                <span class="underline nowrap longarrow-black"
                  >Go to My Cellar</span
                >
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <section v-if="top10Wines" class="home-top10">
      <div class="top10">
        <h2 class="text-center">This Week&rsquo;s Member Favorites</h2>
        <button
          v-if="selectedTop10Wine > 1"
          class="prev"
          @click="goToPrevTop10Wine"
        >
          Prev
        </button>
        <button
          v-if="showTop10NextArrow"
          class="next"
          @click="goToNextTop10Wine"
        >
          Next
        </button>
        <div
          id="top-10-cards"
          ref="top10cards"
          class="cards"
          @scroll="updateTop10WineByScroll"
        >
          <nuxt-link
            v-for="(wine, index) in top10Wines"
            :id="`top-10-${index + 1}`"
            :key="index + 1"
            :to="wine.url"
          >
            <div class="number">{{ index + 1 }}</div>
            <div class="img">
              <img
                v-lazy-load :data-src="wine.bottle_image_url"
                alt="wine"
                class="lazyLoad isLoaded"
              />
              <div class="divider">&nbsp;</div>
            </div>
            <div class="winetagline">
              {{ wine.narrative_tag_line }}
              <div class="winename">{{ wine.title }}</div>
            </div>
          </nuxt-link>
        </div>
      </div>
    </section>
    <section class="home-standard home-logoband">
      <div class="row">
        <div class="columns text-center">
          <h2>In the Press</h2>
          <div class="logos">
            <img
              v-lazy-load data-src="~assets/images/homepage-logo-businessinsider.png"
              alt="Business Insider Logo"
              data-not-lazy
            />
            <img
              v-lazy-load data-src="~assets/images/homepage-logo-foodwine.svg"
              alt="Food and Wine Logo"
              data-not-lazy
            />
            <img
              v-lazy-load data-src="~assets/images/homepage-logo-forbes.png"
              alt="Forbes Logo"
              data-not-lazy
            />
            <img
              v-lazy-load data-src="~assets/images/homepage-logo-vogue.svg"
              alt="Vogue Logo"
              data-not-lazy
            />
            <img
              v-lazy-load data-src="~assets/images/logo-wineenthusiast.svg"
              alt="Wine Enthusiast Logo"
              data-not-lazy
            />
          </div>
        </div>
      </div>
    </section>
    <section class="home-wineclub">
      <div class="row image-copy-split">
        <div class="columns large-6 image-col"></div>
        <div class="columns large-6 col-copy">
          <div class="small-caps-text">
            <em>The New York Times</em> Wirecutter
          </div>
          <h2>
            Wine Club Top Pick<br />
            2023 and 2024
          </h2>
          <nuxt-link to="/club/" class="button button-white">
            Find Your Club
          </nuxt-link>
        </div>
      </div>
    </section>
    <section
      v-if="isPersonalized && flavorRecos && flavorRecos.regions"
      class="home-standard"
    >
      <div class="row">
        <div class="columns text-center">
          <div class="small-caps-text">Wines For You</div>
          <h2>
            Based on Your Wine
            {{ flavorRecos.type === 'purchase' ? 'Purchases' : 'Ratings' }}
          </h2>
          <div class="carousel-container site-width">
            <div id="flavor-recos-tabs" class="tabs">
              <div
                v-for="({ name, slug }, index) in flavorRecos.regions"
                :id="slug"
                :key="index"
                v-scroll-to="{
                  el: `#${slug}`,
                  container: '#flavor-recos-tabs',
                  offset: -50,
                  x: true,
                  y: false,
                }"
                class="tab"
                :class="{ active: slug === activeFlavorReco }"
                :data-collection="slug"
                @click="activeFlavorReco = slug"
              >
                {{ name }}
              </div>
            </div>
            <div class="wine-profiles">
              <div
                v-for="{ name, score, index } in flavorRecoRegion(
                  activeFlavorReco
                ).profile"
                :key="index"
              >
                <div>
                  <img
                    v-if="score >= 1"
                    v-lazy-load data-src="~/assets/images/dot-full.png"
                    alt=""
                  />
                  <img v-else v-lazy-load data-src="~/assets/images/dot-empty.png" alt="" />
                  <img
                    v-if="score >= 2"
                    v-lazy-load data-src="~/assets/images/dot-full.png"
                    alt=""
                  />
                  <img v-else v-lazy-load data-src="~/assets/images/dot-empty.png" alt="" />
                  <img
                    v-if="score >= 3"
                    v-lazy-load data-src="~/assets/images/dot-full.png"
                    alt=""
                  />
                  <img v-else v-lazy-load data-src="~/assets/images/dot-empty.png" alt="" />
                </div>

                {{ name }}
              </div>
            </div>
            <div
              :key="`flavorreco-carousel-${activeFlavorReco}${restaurantFetchCount}`"
            >
              <ProductGridCarousel
                :carousel-id="'flavorReco-' + activeFlavorReco"
                :items="flavorRecoRegion(activeFlavorReco).wines"
                :title="null"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
    <section v-else class="home-standard">
      <div class="row">
        <div class="columns text-center">
          <h2>Shop Collections</h2>
          <div class="carousel-container site-width">
            <div id="collections-tabs" class="tabs">
              <div
                v-for="slug in shopTheCollectionSlugs"
                :id="slug"
                :key="slug"
                v-scroll-to="{
                  el: `#${slug}`,
                  container: '#collections-tabs',
                  offset: -50,
                  x: true,
                  y: false,
                }"
                class="tab"
                :class="{ active: slug === activeShopTheCollectionSlug }"
                :data-collection="slug"
                @click="activeShopTheCollectionSlug = slug"
              >
                {{ collectionName(slug) }}
              </div>
            </div>
            <div
              v-if="collectionProducts(activeShopTheCollectionSlug).length > 0"
              :key="activeShopTheCollectionSlug"
            >
              <ProductGridCarousel
                :carousel-id="activeShopTheCollectionSlug"
                :items="collectionProducts(activeShopTheCollectionSlug)"
                :title="null"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="home-restaurants">
      <div class="row image-copy-split">
        <div class="columns large-6 col-copy">
          <h2>
            Collaborations<br />
            with Award-Winning<br />
            Restaurants
          </h2>
        </div>
        <div
          class="columns large-6 image-col"
          :style="{
            'background-image': `url('${restaurantImage}')`,
            'background-size': 'cover',
          }"
        />
      </div>
    </section>
    <section class="home-standard">
      <div class="row">
        <div class="columns text-center">
          <div class="small-caps-text">Shop by Restaurant</div>
          <h2>Exclusive Collections</h2>
          <div class="carousel-container site-width">
            <div id="restaurant-tabs" class="tabs">
              <div
                v-for="({ name, slug, extended_data },
                index) in restaurantOccasions"
                :id="slug"
                :key="index"
                v-scroll-to="{
                  el: `#${slug}`,
                  container: '#restaurant-tabs',
                  offset: -100,
                  x: true,
                  y: false,
                }"
                class="tab"
                :class="{ active: index === activeRestaurant }"
                :data-collection="slug"
                @click="activeRestaurant = index"
              >
                {{ extended_data.michelin_restaurant_name || name }}
              </div>
            </div>
            <div
              :key="`${carouselRestaurantPrefix}carousel-${activeRestaurant}${restaurantFetchCount}`"
            >
              <ProductGridCarousel
                :carousel-id="carouselRestaurantPrefix + activeRestaurant"
                :items="restaurantItems"
                :title="null"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
    <intersect @enter.once="qesDisplayed = true">
      <section class="email-signup">
        <QuickEmailSignupModal
          v-if="showQES"
          :open="qesDisplayed"
          @closed="qesDisplayed = false"
        />
      </section>
    </intersect>
    <section class="home-standard home-customerreview text-center">
      <div class="site-width quote-carousel-container">
        <h2>What Customers Are Saying</h2>
        <client-only>
          <carousel v-bind="carouselOptions">
            <slide v-for="(quote, index) in quotes" :key="index">
              <figure>
                <blockquote>“{{ quote.quote }}”</blockquote>
                <figcaption>— {{ quote.author_name }}</figcaption>
              </figure>
            </slide>
          </carousel>
        </client-only>
      </div>
    </section>
    <section class="home-shipping">
      <div class="row">
        <div class="columns large-6 col-copy">
          <img
            v-lazy-load data-src="~assets/images/homepage-red-bottles.svg"
            alt="Red Wine Bottle Logo"
          />
          <div class="copy-container">
            <h2>
              Never Leave a Bottle on the Table
            </h2>
            <p class="subheader">
              Buy the bottles that catch your eye—we’re happy to hold them for
              up to 30 days, or until you reach the complimentary shipping
              threshold of $150. Then choose your delivery date and you’re all
              set!
            </p>
          </div>
        </div>
        <div class="columns large-3 image-col"></div>
        <div class="columns large-3 col-copy">
          <div class="copy-container">
            <h2>Never Settle</h2>
            <p>
              If any wine fails to impress, we'll credit that bottle.
            </p>
          </div>
        </div>
      </div>
    </section>
    <section class="home-standard home-collections" style="border-bottom: 0;">
      <div class="row">
        <div class="columns text-center">
          <h2>Featured Collections</h2>
          <div class="row grid-three-up">
            <div
              v-for="o in featuredOccasions"
              :key="o.id"
              class="columns small-12 medium-4"
            >
              <picture>
                <source v-lazy-load :data-srcset="o.thumbnail_optimized" type="image/webp" />
                <source v-lazy-load :data-srcset="o.thumbnail" type="image/jpeg" />
                <img v-lazy-load :data-src="o.image" :alt="`${o.name} Selection`" />
              </picture>
              <h3>{{ o.name }}</h3>
              <nuxt-link :to="`/store/occasion/${o.slug}/`" class="button"
                >Shop the Collection</nuxt-link
              >
            </div>
          </div>
        </div>
      </div>
    </section>
    <SavedSearchModal
      :show="showSavedSearchModal"
      @close="showSavedSearchModal = false"
    />
  </div>
</template>

<script>
import Intersect from 'vue-intersect'
import Vue from 'vue'
import _ from 'lodash'
import { mapGetters } from 'vuex'
import { slugify } from '~/../common/utils/slugify'
import { showPersonalized } from '~/../common/services/customerServices'
import { scrollToProductInCarousel } from '~/utils/product'

const getHomepageData = async ($axios, $auth) => {
  const homepageData = (await $axios.get('/api/homepage/')).data
  const shopTheCollectionMap = homepageData.shop_the_collection.reduce(
    (obj, coll) => Object.assign(obj, { [coll.slug]: coll }),
    {}
  )
  let shopTheCollectionSlugs = defaultShopTheCollectionSlugs
  let activeShopTheCollectionSlug = 'popular'

  if (showPersonalized($auth)) {
    shopTheCollectionMap.personalized = {
      name: 'Wines For You',
      slug: 'personalized',
      products: homepageData.personalized_products,
    }
    shopTheCollectionSlugs = loggedInShopTheCollectionSlugs
    activeShopTheCollectionSlug = 'personalized'
  }

  let flavorRecos = homepageData.flavor_profile_recommendations
  let activeFlavorReco = null

  if (flavorRecos) {
    if (flavorRecos.regions && flavorRecos.regions.length >= 1) {
      flavorRecos = {
        ...flavorRecos,
        regions: flavorRecos.regions.map((r) => {
          const name = Object.keys(r)[0]
          return {
            ...r[name],
            name,
            slug: slugify(name),
            profile: Object.keys(r[name].profile).map((profileName) => ({
              name: profileName,
              score: r[name].profile[profileName],
            })),
          }
        }),
      }
      activeFlavorReco = flavorRecos.regions[0].slug
    }
  }

  let personalizedSearches = homepageData.top_categories
  if (personalizedSearches) {
    const napaSearch = {
      slug: 'napa-valley-cabernet-sauvignon',
      display_name: 'Napa Cabernet',
    }
    if (!personalizedSearches.find((s) => s.slug === napaSearch.slug)) {
      personalizedSearches = personalizedSearches.concat([napaSearch])
    }
    const champSearch = {
      slug: 'champagne-sparkling',
      display_name: 'Champagne',
    }
    if (!personalizedSearches.find((s) => s.slug === champSearch.slug)) {
      personalizedSearches = personalizedSearches.concat([champSearch])
    }
    personalizedSearches = personalizedSearches.slice(0, 3)
  }

  return {
    commonSearches: homepageData.common_searches,
    restaurantOccasions: homepageData.michelin_restaurant_occasions,
    occasions: homepageData.homepage_occasions,
    quotes: homepageData.quotes,
    top10Wines: homepageData.top_10_wines,
    userHasOrders: homepageData.user_has_orders,
    homepageHeroWidgetData: homepageData.homepage_hero_widget_data,
    flavorRecos,
    activeFlavorReco,
    personalizedSearches,
    shopTheCollectionMap,
    shopTheCollectionSlugs,
    activeShopTheCollectionSlug,
  }
}

const defaultShopTheCollectionSlugs = [
  'popular',
  'limited',
  'wine-team-favorites',
  'final-few',
]
const loggedInShopTheCollectionSlugs = [
  'personalized',
  'limited',
  'wine-team-favorites',
  'final-few',
]
export default {
  name: 'WineAccessHomepage',
  components: {
    Intersect,
    ProductGridCarousel: () =>
      import('~/components/Product/ProductGridCarousel'),
    QuickEmailSignupModal: () =>
      import('~/components/Index/QuickEmailSignupModal.vue'),
    SavedSearchModal: () => import('~/components/Cellar/SavedSearchModal.vue'),
  },
  async asyncData({ $axios, $auth }) {
    return await getHomepageData($axios, $auth)
  },
  data() {
    return {
      commonSearches: {},
      personalizedSearches: null,
      flavorRecos: null,
      activeFlavorReco: 0,
      userHasOrders: false,
      activeShopTheCollectionSlug: 'popular',
      restaurantOccasions: [],
      activeRestaurant: 0,
      restaurantFetchCount: 0,
      occasions: [],
      quotes: [],
      top10Wines: [],
      selectedTop10Wine: 1,
      showTop10NextArrow: true,
      shopTheCollectionSlugs: [],
      shopTheCollectionMap: {},
      qesDisplayed: false,
      qesHasDisplayed: false,
      carouselOptions: {
        autoplay: true,
        autoplayTimeout: 10000,
        perPage: 1,
        loop: true,
        navigationEnabled: true,
        navigationNextLabel: '',
        navigationPrevLabel: '',
      },
      carouselRestaurantPrefix: 'restaurant-',
      screenWidth: process.client ? window.innerWidth : 0,
      preloadHeroImage: '',
      homepageHeroWidgetData: null,
      showSavedSearchModal: false,
    }
  },
  head() {
    // allow dynamically head updates
    return this.dynamicHead
  },
  computed: {
    ...mapGetters([
      'productIDInView',
      'carouselInView',
      'qes',
      'savedSearchList',
    ]),
    showQES() {
      return (
        this.qesDisplayed &&
        !this.loggedIn &&
        !(this.qes || this.$cookies.get('new-quicksignup'))
      )
    },
    loggedIn() {
      return this.$auth.loggedIn
    },
    isPersonalized() {
      return showPersonalized(this.$auth)
    },
    featuredOccasions() {
      return this.occasions.slice(0, 3)
    },
    restaurantItems() {
      const res = this.restaurantOccasions[this.activeRestaurant]
      if (res) return res.products
      return []
    },
    restaurantImage() {
      let imageString = ''
      if (this.restaurantOccasions.length > 0) {
        imageString = this.restaurantOccasions[this.activeRestaurant]
          .extended_data.michelin_image
        if (imageString) {
          imageString = this.$config.cloudfrontBase + imageString
        }
      }
      return imageString || '/_nuxt/assets/images/homepage-restaurant-image.png'
    },
    dynamicHead() {
      return {
        link: [
          {
            rel: 'preload',
            as: 'image',
            href: this.preloadHeroImage,
            key: 'hero-preload',
          },
        ],
      }
    },
  },
  watch: {
    loggedIn: {
      async handler(newValue, oldValue) {
        const authStateHasChanged =
          (oldValue === false && this.isPersonalized) ||
          (oldValue === true && !newValue)
        if (authStateHasChanged) {
          _.forEach(await getHomepageData(this.$axios, this.$auth), (v, k) => {
            this[k] = v
          })
        }
      },
      immediate: true,
    },
    activeRestaurant: {
      async handler(newValue, oldValue) {
        // Fetch the products of the  restaurant if we don't have them already
        if (!this.restaurantOccasions[newValue].products) {
          const slug = this.restaurantOccasions[newValue].slug
          Vue.set(
            this.restaurantOccasions[newValue],
            'products',
            (
              await this.$axios.get(
                `/api/catalog/search/?occasions=${slug}&page_size=12`
              )
            ).data.results
          )
          this.restaurantFetchCount += 1
        }
        scrollToProductInCarousel(this.$store, this.$nextTick)
      },
    },
  },
  async created() {
    if (this.$auth.loggedIn) {
      await this.$store.dispatch('getSavedSearches')
    }
  },
  mounted() {
    // eslint-disable-next-line nuxt/no-env-in-hooks
    if (process.client) {
      // update hero images dynamically
      this.screenWidth = window.innerWidth
      this.updateHeroPreload()
      window.addEventListener('resize', this.updateHeroPreload)
    }
    // If the previous product and carousel are set then make sure the correct carousel is selected,
    // then scroll to the restaurant one scrolls to it after the async fetch of the products
    if (
      this.productIDInView !== undefined &&
      this.carouselInView !== undefined
    ) {
      if (this.carouselInView.startsWith(this.carouselRestaurantPrefix)) {
        const regex = /(\d+)$/
        const match = this.carouselInView.match(regex)
        if (match) {
          this.activeRestaurant = parseInt(match[1])
        }
      } else {
        this.activeShopTheCollectionSlug = this.carouselInView
        scrollToProductInCarousel(this.$store, this.$nextTick)
      }
    }
  },
  beforeDestroy() {
    // eslint-disable-next-line nuxt/no-env-in-hooks
    if (process.client) {
      // removes custom event
      window.removeEventListener('resize', this.updateHeroPreload)
    }
  },
  methods: {
    searchLink(search) {
      return `/store/${search.slug}/`
    },
    collectionName(slug) {
      return this.shopTheCollectionMap[slug]
        ? this.shopTheCollectionMap[slug].name || 'Collection'
        : 'Collection'
    },
    collectionProducts(slug) {
      return this.shopTheCollectionMap[slug]
        ? this.shopTheCollectionMap[slug].products || []
        : []
    },
    flavorRecoRegion(slug) {
      return (
        (this.flavorRecos.regions || []).find((fr) => fr.slug === slug) || {}
      )
    },
    goToNextTop10Wine() {
      this.$scrollTo(`#top-10-${this.selectedTop10Wine + 1}`, 500, {
        container: '#top-10-cards',
        offset: 0,
        x: true,
        y: false,
      })
    },
    goToPrevTop10Wine() {
      this.$scrollTo(
        `#top-10-${Math.max(1, this.selectedTop10Wine - 1)}`,
        500,
        {
          container: '#top-10-cards',
          offset: 0,
          x: true,
          y: false,
        }
      )
    },
    updateTop10WineByScroll({
      target: { scrollLeft, scrollWidth, clientWidth },
    }) {
      const firstWidth = this.$refs.top10cards.children[0].scrollWidth
      const nthWidth = this.$refs.top10cards.children[1].scrollWidth
      if (scrollLeft < firstWidth) {
        this.selectedTop10Wine = 1
      } else {
        this.selectedTop10Wine =
          Math.floor((scrollLeft - firstWidth) / nthWidth) + 2
      }
      this.showTop10NextArrow =
        scrollLeft + nthWidth < scrollWidth - clientWidth
    },
    updateHeroPreload() {
      // eslint-disable-next-line nuxt/no-env-in-hooks
      if (process.client) {
        this.screenWidth = window.innerWidth
        const cloudfrontBase =
          process.env.CLOUDFRONT_BASE || 'https://static.qa-wineaccess.com/'

        if (this.isPersonalized) {
          if (this.screenWidth >= 768) {
            // contemplates resolutions sizes equal or up to 1024
            this.preloadHeroImage = `${cloudfrontBase}images/home-personalized-large.webp`
          } else {
            this.preloadHeroImage = `${cloudfrontBase}images/home-personalized-small.webp`
          }
        } else if (this.screenWidth >= 1024) {
          this.preloadHeroImage = `${cloudfrontBase}images/home-large.webp`
        } else if (this.screenWidth >= 768) {
          this.preloadHeroImage = `${cloudfrontBase}images/home-medium.webp`
        } else {
          this.preloadHeroImage = `${cloudfrontBase}images/home-small.webp`
        }
      }
    },
  },
}
</script>
